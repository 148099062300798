import React, { useState } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../Authentication/authSlice';
import { useGetProfileQuery } from '../Profile/ProfileSlice';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch()

  const {
    data: profile
  } = useGetProfileQuery('getProfile')

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <nav className="navbar pb-3" style={{ backgroundColor: "#DADFE0" }}>
        <div className="container-fluid d-flex justify-content-between align-items-center">
          <div >
            <img
              className="navbar-brand"
              src="/assets/images/ecowrap-logo.png"
              alt="ecowrap-logo"
              style={{ width: "200px" }}
            />
          </div>
          <div className="dropdown-container position-relative">
            <button
              className="btn btn-success rounded-circle"
              type="button"
              onClick={toggleDropdown}
              style={{
                width: "40px",
                height: "40px",
                padding: "0",
                zIndex: "1051",
              }}
            >
              {profile?.entities[profile?.ids]?.ca_name[0]}
            </button>

            {/* Dropdown Menu */}
            {isOpen && (
              <div
                className="card dropdown-menu p-0"
                style={{ position: "absolute", top: "50px", right: "0", transform: "translateX(5%)", zIndex: 130, backgroundColor: "#fff", borderRadius: "5px", }}
              >
                <ul onClick={() => { setIsOpen(!isOpen) }} style={{ listStyle: "none", margin: "0" }}>
                  <li>
                    <Link className="dropdown-item" to="/profile">
                      Profile
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/password">
                      Password
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => dispatch(logout())} className="dropdown-item" to="/login">
                      Logout
                    </Link>
                  </li>
                </ul>
              </div>
            )}

          </div>
        </div>

      </nav>
    </>
  );
};

export default Navbar;
