import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetReportDetailsQuery } from '../ReportSlice';
import Spinner from '../../../Components/Spinner/SpinnerForTable';
import moment from 'moment';
import AiIMage from '../../../Components/utils/AiIMage';



const ReportDetails = () => {
    const { id } = useParams();


    const {
        data: reportDetails,
        isLoading,
        error,
    } = useGetReportDetailsQuery({ id })

    const rating = reportDetails?.rating_of_image?.split(",")
    const category = reportDetails?.identified_category_of_images?.split("}")


    return (
        < >
            {error && <div>Someting Went Wrong</div>}
            {isLoading && <Spinner />}
            {reportDetails && <div className='report d-flex overflow-scroll  flex-column gap-5 align-items-center   pt-5'>

                <div className="report-card flex-column gap-4 h-auto">
                    <h4>Property Details:</h4>
                    <div className='row'>
                        <p className='col-12'><strong>House Number : </strong>{reportDetails?.house_number}</p>
                        <p className='col-12'><strong>Full Address : </strong>{reportDetails?.full_address}</p>
                        <p className='col-12'><strong>Street : </strong>{reportDetails?.street}</p>
                        <p className='col-6'><strong>Total Member : </strong>{reportDetails?.total_members === -1 ? '0' : reportDetails?.total_members}</p>
                        <p className='col-6'><strong>Collection Date : </strong>{moment(reportDetails?.collected_at).format("DD/MM/YYYY")}</p>
                    </div>
                </div>


                <AiIMage imagesArr={reportDetails?.images_names?.split(",")} images_names={reportDetails?.images_names} rating={rating} category={category} />

            </div>}
        </>
    )
}

export default ReportDetails