import React, { useState, useRef, useEffect } from "react";
import DailyWasteExcerpt from "./DailyWasteExcerpt";
import Pagination from "../../Components/utils/Pagination";
import { useGetDailyWasteQuery, useGetSubWasteCategoryQuery } from "./dailyWasteSlice";
import { TableHead } from "../../Components/utils/TableFormat";

const DailyWaste = () => {

    const thead = ['Sr. No.', 'Collection Date', 'Waste', 'Total Waste']
    const [page, setPage] = useState(1)

    useEffect(() => {
        sessionStorage.setItem('dailyWastePage', page);
    }, [page]);

    const {
        data: dailyWaste,
        isError,
    } = useGetDailyWasteQuery(page)

    const { data: subCategory, } = useGetSubWasteCategoryQuery('getSubWasteCategory')

    return (

        <div className='property'>
            {isError && <div>Something Went Wrong</div>}
            <div style={{ transition: "all 0.3s ease-in-out" }} className=' px-2 d-flex justify-content-center gap-3 flex-wrap  py-3 mb-5 ' >
                <div className=" overflow-scroll" style={{ width: "85%" }}>
                    <table className="table px-5 overflow-hidden rounded table-borderless table-striped table-hover " >
                        <TableHead thead={thead} />
                        <tbody>
                            {
                                dailyWaste?.entities?.map((dailyWaste, index) => {
                                    return (
                                        <DailyWasteExcerpt key={index} page={page} dailyWaste={dailyWaste} index={index} subCategory={subCategory} />
                                    )
                                })
                            }
                        </tbody>
                    </table>

                </div>

                <Pagination page={page} totalPages={dailyWaste?.totalPage || 0} setPage={setPage} />
            </div>

        </div>

    )
}

export default DailyWaste;