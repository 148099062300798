import { apiSlice } from "../../Features/api/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getProperties: builder.query({
            query: ({ page, searchData }) => `/survey/property?page=${page}&house_number=${searchData.house_number || ''}&u_number=${searchData.u_number || ''}&u_name=${searchData.u_name || ''}&ward_id=${searchData.ward_id || ''}`,
            transformResponse: (responseData) => {

                const { pagination, result } = responseData?.data[0] || {};
                return { pagination, entities: result };
            },
            providesTags: (results, error, arg) => {
                if (error) {
                    return [];
                }
                if (results?.ids) {
                    return [
                        { type: 'Properties', id: "List" },
                        ...results.ids.map(id => ({ type: 'Properties', id })),
                    ];
                }
                return [];
            },
        }),
        getPropertieDetail: builder.query({
            query: ({ id }) => `/survey/property/${id}`,
            transformResponse: (responseData) => {
                const { userData, propertyData, rating_history } = responseData?.data[0] || {};
                return { userData, propertyData, rating_history };
            },
        }),
        getWardList: builder.query({
            query: () => '/survey/wards',
            transformResponse: (responseData) => {
                return responseData?.data;
            },
        })
    }),
});

export const {
    useGetPropertiesQuery,
    useGetPropertieDetailQuery,
    useGetWardListQuery
} = extendedApiSlice;
