import React, { useEffect, useState } from 'react'
import { useGetPropertiesQuery, useGetWardListQuery } from './PropertiesSlice';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../Components/Spinner/SpinnerForTable';
import { TableHead } from '../../Components/utils/TableFormat';
import Pagination from '../../Components/utils/Pagination';

const PropertiesList = () => {

    const [search, setSearch] = useState({})
    const [searchData, setSearchData] = useState({})
    const [page, setPage] = useState(() => {
        const savedPage = sessionStorage.getItem('dailyReportPage');
        return savedPage ? Number(savedPage) : 1;
    })


    useEffect(() => {
        sessionStorage.setItem('dailyReportPage', page);
    }, [page]);
    const navigate = useNavigate()
    const thead = ['SR.', 'Property', 'Contact Person', 'Contact Number', 'Area', 'Property Status', 'Average Rating'];

    const {
        data: properties,
        isLoading,
        error,
    } = useGetPropertiesQuery({ page, searchData });

    const {
        data: wardList
    } = useGetWardListQuery('getWardList');


    const changeHandle = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        setSearch({ ...search, [name]: value })
    }

    return (
        <>
            {error && <div className='d-flex justify-content-center'>Someting Went Wrong  {error?.data?.message}</div>}
            {isLoading && <Spinner />}
            {properties &&
                <div className='property'>
                    <div className='pt-3 d-flex justify-content-center gap-3 flex-wrap'>
                        <input onChange={changeHandle} value={search.house_number} name='house_number' className='search-box w-25 form-control' placeholder='Search Property' type='text' />
                        <input onChange={changeHandle} value={search.u_name} name='u_name' className='search-box w-25 form-control' placeholder='Search Contact Person' type='text' />
                        <input onChange={changeHandle} value={search.u_number} name='u_number' className='search-box w-25 form-control' placeholder='Search Contact Number' type='number' />
                        <select onChange={changeHandle} value={search.ward_id} name='ward_id' className='search-box form-select w-25'>
                            <option>Select Ward</option>
                            {wardList && wardList?.map((e) => {
                                return (
                                    <option value={e.ward_id}>{e.ward_number}  {e.community_name}</option>
                                )
                            })}
                        </select>
                        <button onClick={() => setSearchData(search)} className='btn btn-success'  >Search Data</button>
                        <button onClick={() => { setSearchData({ house_number: '', u_name: '', u_number: '', ward_id: '' }); setSearch({ house_number: '', u_name: '', u_number: '', ward_id: '' }) }} className='btn btn-success'  >Reset</button>
                    </div>
                    <div style={{ transition: "all 0.3s ease-in-out" }} className='  d-flex justify-content-center gap-3 flex-wrap  py-3 mb-5 ' >
                        <div className="overflow-scroll" style={{ width: "85%" }}>
                            <table className="table px-5 full-size overflow-hidden rounded table-borderless table-striped table-hover "  >
                                <TableHead thead={thead} />
                                <tbody className=''>
                                    {properties?.entities?.map((entity, index) => {
                                        const property_number = entity.full_address.split(",")[entity.full_address.split(",").length - 5]
                                        const street_name = entity.full_address.split(",")[entity.full_address.split(",").length - 4]
                                        return (
                                            <tr onClick={() => navigate(`/propertydetails/${entity.prp_id}`)} className="listData text-left fw-normal" key={index}>
                                                <td>{index + 1 + (page - 1) * 10}</td>
                                                <td>{entity.house_number}</td>
                                                <td>{entity.u_name}</td>
                                                <td>{entity.u_number === '6000000000' ? 'NA' : entity.u_number}</td>
                                                <td>{property_number || street_name ? <span>{property_number} , {street_name}</span> : 'N/A'}</td>
                                                <td>{entity.status}</td>
                                                <td>{entity.average_rating === null ? 'NA' : entity.average_rating.toFixed(1)}</td>
                                            </tr>
                                        )
                                    }
                                    )}
                                </tbody>
                            </table>
                        </div>
                        {properties?.entities?.map((e, i) => {
                            const property_number = e.full_address.split(",")[e.full_address.split(",").length - 5]
                            const street_name = e.full_address.split(",")[e.full_address.split(",").length - 4]
                            return (
                                <div key={e?.id} onClick={() => navigate(`/propertydetails/${e.prp_id}`)} className="mob-size report-card flex-column gap-4 h-auto" style={{ width: '150px !important' }} >
                                    <h4>{i + 1 + (page - 1) * 10}.</h4>
                                    <div className='row'>
                                        <p className='col-12'><strong>House Number : </strong>{e.house_number}</p>
                                        <p className='col-12'><strong>Name : </strong> {e.u_name} </p>
                                        <p className='col-12'><strong>Street : </strong>{e.property_number || street_name ? <span>{property_number} , {street_name}</span> : 'N/A'}  </p>
                                        <p className='col-12'><strong>number : </strong>  {e.u_number === '6000000000' ? 'NA' : e.u_number} </p>
                                        <p className='col-12'><strong>Status : </strong>{e.status}  </p>
                                    </div>
                                </div>
                            )
                        })}
                        <Pagination page={page} totalPages={properties?.pagination?.totalPages || 0} setPage={setPage} />
                    </div>
                </div>
            }
        </>
    )
}

export default PropertiesList