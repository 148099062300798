import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../Components/Spinner/SpinnerForTable'
import Pagination from '../../Components/utils/Pagination'
import { TableHead } from '../../Components/utils/TableFormat'
import { useGetReportDateQuery } from './ReportSlice'
import moment from 'moment'

const ReportDateList = () => {
    const [page, setPage] = useState(1)
    const navigate = useNavigate()
    const thead = ['Report Date', "Total Collection"];

    const {
        data: ReportDateList,
        isLoading
    } = useGetReportDateQuery({ page })

    return (
        <>
            {isLoading && <Spinner />}
            {ReportDateList &&
                <div className='collection d-flex justify-content-center pt-3'>
                    <div className="collection-card w-100 h-auto overflow-scroll">
                        <table className="table table-borderless h-auto table-striped table-hover">
                            <TableHead thead={thead} />
                            <tbody>
                                {ReportDateList?.entities?.map((e, index) => {
                                    return (
                                        <>
                                            <tr key={index} onClick={() => navigate(`/report/${moment(e.date).format("YYYY-MM-DD").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}`)} className="listData text-left fw-normal" >
                                                <td>{moment(e.date).format("YYYY-MM-DD").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}</td>
                                                <td>{e.total}</td>
                                            </tr>
                                        </>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <Pagination page={page} totalPages={ReportDateList?.total_page || 0} setPage={setPage} />
                </div>}
        </>
    )
}

export default ReportDateList