import React from 'react'
export const TableHead = ({ thead, view = true }) => {
    return (

        <thead className={`bg-success ${view ? '' : 'full-size'} text-light`}>
            <tr>
                {thead.map((e) => <td key={e}><b>{e}</b></td>)}
            </tr>
        </thead>

    )
}
