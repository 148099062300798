import moment from "moment";
import { useState, useRef, useEffect } from "react";

const DailyWasteExcerpt = ({ page, dailyWaste, index, subCategory }) => {
    let kg = 0;
    let litre = 0;
    let box = 0;
    let wasteData = [];
    if (dailyWaste) {
        wasteData = JSON.parse(dailyWaste?.w_waste);
    }

    const [isExpanded, setIsExpanded] = useState(false);
    const [height, setHeight] = useState("27px");
    const parentRef = useRef(null);

    const handleChildClick = () => {
        if (isExpanded) {
            setHeight("27px");
        } else {
            const scrollHeight = parentRef.current.scrollHeight + "px";
            setHeight(scrollHeight);
        }
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        if (!isExpanded) {
            const timeout = setTimeout(() => {
                setHeight("27px");
            }, 300);
            return () => clearTimeout(timeout);
        }
    }, [isExpanded]);

    const wasteDataShow2 = () => {
        return (
            <div
                ref={parentRef}
                className="overflow-hidden"
                style={{
                    height: height,
                    transition: "height 0.3s ease-in-out",
                }}
            >
                {subCategory?.ids?.map((ids, i) => {
                    const matchingWaste = wasteData.find(([wasteId]) => wasteId === ids) || [];
                    if (!matchingWaste[1]) {
                        return null;
                    }
                    return (
                        <div
                            onClick={handleChildClick}
                            key={`${index}-${i}`}
                            className={`d-flex  ${isExpanded ? "flex-column" : ""
                                }`}
                        >
                            {`${subCategory?.entities[ids]?.wsc_name} - ${matchingWaste ? matchingWaste[1] : 0
                                }${subCategory?.entities[ids]?.wsc_measure_unit}`}
                            <span style={{ display: isExpanded ? "none" : "inline" }}>....</span>
                        </div>
                    );
                })}
            </div>
        );
    };

    subCategory?.ids?.map((id) => {
        const matchingWaste = wasteData.find(([wasteId]) => wasteId === id);
        if (subCategory?.entities[id]?.wsc_measure_unit === "kg") {
            kg += matchingWaste ? matchingWaste[1] : 0;
        } else if (subCategory?.entities[id]?.wsc_measure_unit === "ton") {
            kg += (matchingWaste ? matchingWaste[1] : 0) * 1000;
        } else if (subCategory?.entities[id]?.wsc_measure_unit === "litre") {
            litre += matchingWaste ? matchingWaste[1] : 0;
        } else if (subCategory?.entities[id]?.wsc_measure_unit === "box") {
            box += matchingWaste ? matchingWaste[1] : 0;
        }
    });

    return (
        <>
            <tr className="fw-normal  " key={index}>
                <td>{index + page * 10 - 9}</td>
                <td>{moment(dailyWaste?.w_collection_date).format("DD/MM/YYYY")}</td>
                <td className="fw-bold">{wasteDataShow2()}</td>
                <td className="fw-bold">
                    {kg !== 0 && <p>{kg} kg</p>}
                    {litre !== 0 && <p>{litre} Litre</p>}
                    {box !== 0 && <p>{box} Box</p>}
                </td>
            </tr>

            {/* <div className="property-card mob-size w-100 flex-wrap my-3" style={{ backgroundColor: '#E2E5E6' }}  >
                <p className="p-0"><b>{index + page * 10 - 9}.</b></p>
                <div className="d-flex flex-wrap gap-3 justify-content-between p-0">
                    <p className="p-0"><b>Collection Date :</b> {moment(dailyWaste?.w_collection_date).format("DD/MM/YYYY")}</p>
                    <p className="p-0"><b>Total Waste:</b>
                        {kg !== 0 && <span> {kg} kg </span>}
                        {litre !== 0 && <span> {litre} Litre </span>}
                        {box !== 0 && <span> {box} Box </span>}
                    </p>
                </div>
                <p className="p-0"><b>Waste:<span> {wasteDataShow2()}</span></b></p>
            </div> */}
        </>
    );
};

export default DailyWasteExcerpt;
