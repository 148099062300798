import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../Features/api/apiSlice";

export const dailyWasteAdaptor = createEntityAdapter({
    selectId: (entity) => entity.w_id,
})

export const subWasteCategoryAdaptor = createEntityAdapter({
    selectId: (entity) => entity.wsc_id,
})
const initialSubWasteCategory = subWasteCategoryAdaptor.getInitialState()

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDailyWaste: builder.query({
            query: (page) => `/waste?page=${page}`,
            transformResponse: responseData => {
                const { totalPage, data, page } = responseData?.data
                return { totalPage, entities: data, page };
            },
            providesTags: (result, error, arg) => {
                if (error) {
                    return []
                }
                if (result?.entities) {
                    return [
                        { type: 'DailyWaste', id: 'List' },
                        ...result.entities.map((dailyWaste) => ({ type: 'DailyWaste', id: dailyWaste.id })),
                    ];
                }
            }
        }),

        getSubWasteCategory: builder.query({
            query: () => '/sub-category',
            transformResponse: responseData => {
                if (responseData?.status === false) {
                    throw new Error(responseData?.statusCode)
                }
                return subWasteCategoryAdaptor.setAll(initialSubWasteCategory, responseData?.data)
            },
            providesTags: (result, error, arg) => {
                if (error) {
                    return []
                }
                if (!error && result?.ids) {
                    return [
                        { type: 'B2GSubWasteCategory', id: "List" },
                        ...result.ids.map(id => ({ type: 'SubWasteCategory', id }))
                    ]
                }
            }
        }),

    })
})

export const {
    useGetDailyWasteQuery,
    useGetSubWasteCategoryQuery

} = extendedApiSlice

