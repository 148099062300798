import { apiSlice } from "../../Features/api/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getReportDate: builder.query({
            query: ({ page }) => `/segregation?page=${page}&limit=10`,
            transformResponse: (responseData) => {
                const { total_page, data } = responseData?.data || {};

                return { total_page, entities: data };
            },
            providesTags: (results, error, arg) => {
                if (error) {
                    return [];
                }
                if (results?.ids) {
                    return [
                        { type: 'ReportDate', id: "List" },
                        ...results.ids.map(id => ({ type: 'ReportDate', id })),
                    ];
                }
                return [];
            },
        }),
        getReport: builder.query({
            query: ({ filterData, page, date }) => `/segregation/${date}?page=${page}&date=${filterData.date}&name=${filterData.name}&ward_id=${filterData.ward_id || ''}`,
            transformResponse: (responseData) => {
                const { totalPage, data } = responseData?.data || {};

                return { totalPage, entities: data };
            },
            providesTags: (results, error, arg) => {
                if (error) {
                    return [];
                }
                if (results?.ids) {
                    return [
                        { type: 'Report', id: "List" },
                        ...results.ids.map(id => ({ type: 'Report', id })),
                    ];
                }
                return [];
            },
        }),
        getReportDetails: builder.query({
            query: ({ id }) => `/segregation-detail/${id}`,
            transformResponse: (responseData) => {
                return responseData?.data[0]
            },
            providesTags: (results, error, arg) => {
                if (error) {
                    return [];
                }
                if (results?.ids) {
                    return [
                        { type: 'ReportDetail', id: "List" },
                        ...results.ids.map(id => ({ type: 'ReportDetail', id })),
                    ];
                }
                return [];
            },
        }),


    }),
});

export const {
    useGetReportQuery,
    useGetReportDetailsQuery,
    useGetReportDateQuery
} = extendedApiSlice;