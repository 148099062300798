import React, { useEffect } from 'react'

const Pagination = ({ page, totalPages, setPage }) => {

    const pageMin = () => setPage((prev) => Math.max(prev - 1, 1));
    const pageAdd = () => setPage((prev) => Math.min(prev + 1, totalPages));
    const firstPage = () => setPage(1);
    const lastPage = () => setPage(totalPages);

    if (totalPages !== 0 && page > totalPages) {
        setPage(totalPages)
    }


    return (
        <div className="pagination_box fixed-bottom mt-3 mb-3">
            <div className=" d-flex justify-content-center ">
                <div className="pagination d-flex gap-3 ">
                    <button className="page-link text-dark  rounded" onClick={firstPage} style={{ backgroundColor: '#D9DBDC' }}>
                        1
                    </button>
                    <button className="page-link text-dark  rounded d-flex" disabled={page <= 1} onClick={pageMin} style={{ backgroundColor: '#D9DBDC' }}>
                        &laquo;Prev
                    </button>
                    <div style={{ backgroundColor: '#D9DBDC' }} className="page-link text-dark  rounded">{page}</div >
                    <button className="page-link text-dark  rounded" disabled={page >= totalPages} onClick={pageAdd} style={{ backgroundColor: '#D9DBDC' }}>
                        Next&raquo;
                    </button>
                    <button className="page-link text-dark  rounded " onClick={lastPage} style={{ backgroundColor: '#D9DBDC' }}>
                        {totalPages}
                    </button>

                </div>
            </div>
        </div>
    )
}

export default Pagination