import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetPropertieDetailQuery } from '../PropertiesSlice';
import { TableHead } from '../../../Components/utils/TableFormat';
import moment from 'moment';
import AiIMage from '../../../Components/utils/AiIMage';
import "./PropertyDetails.css"
import Spinner from '../../../Components/Spinner/SpinnerForTable';

const PropertyDetails = () => {
    const [clickedRowIndex, setClickedRowIndex] = useState(null); // Track the clicked row index
    const [expandedRemarkIndex, setExpandedRemarkIndex] = useState(null); // Track expanded remark

    const toggleRemarkExpansion = (index) => {
        setExpandedRemarkIndex(expandedRemarkIndex === index ? null : index);
    };

    const thead = ['Date Collected', 'Waste Provided', 'Waste Category', 'Segregated', 'Rating', "Remark"];
    const { id } = useParams();
    const { data: propertydetail, isFetching } = useGetPropertieDetailQuery({ id });
    const prp_data = propertydetail?.propertyData[0];
    const u_data = propertydetail?.userData[0];
    const rating_data = propertydetail?.rating_history;

    const property_number = prp_data?.full_address.split(',')[prp_data?.full_address.split(',').length - 5];
    const street_name = prp_data?.full_address.split(',')[prp_data?.full_address.split(',').length - 4];

    if (isFetching) return <Spinner />

    return (
        <div>
            <div className="w-100 h-auto d-flex justify-content-center pt-3">
                <div className="px-3 mb-4 flex-column" style={{ width: '90%', height: "auto", minHeight: '78vh' }}>
                    <h3>Property Details :</h3>
                    <div
                        className="property-card w-100 flex-wrap justify-content-between gap-3"
                        style={{ backgroundColor: '#E2E5E6' }}
                    >
                        <p><b>Property number:</b>{prp_data?.house_number}</p>
                        <p><b>Property status:</b> {prp_data?.status}</p>
                        <p><b>Property type:</b> {prp_data?.property_type}</p>
                        <p><b>Property floor:</b> {prp_data?.floor}</p>
                        <p>
                            <b>Property street:</b>
                            {property_number || street_name ? (
                                <span>{property_number}, {street_name}</span>
                            ) : 'N/A'}
                        </p>
                        <p><b>Property full address:</b> {prp_data?.full_address}</p>
                    </div>

                    <h3 className="mt-4">Users :</h3>
                    <div className="property-card w-100" style={{ backgroundColor: '#E2E5E6' }}>
                        <div className="d-flex w-100 flex-wrap justify-content-around gap-3 text-start">
                            <p><b>Name:</b> {u_data?.u_name}</p>
                            <p><b>Number:</b> {u_data?.u_number === '6000000000' ? 'NA' : u_data?.u_number}</p>
                        </div>
                    </div>

                    <h3 className="mt-4">Rating History :</h3>
                    <div className="w-100">
                        {rating_data?.length === 0 ? <div className='text-danger fw-bold'>No Rating History for This Property</div> :
                            <table className="table px-5 full-size overflow-hidden rounded table-borderless table-striped table-hover">
                                <TableHead thead={thead} />
                                <tbody>
                                    {rating_data?.map((e, index) => (
                                        <React.Fragment key={index}>
                                            <tr
                                                className="listData text-left fw-normal"
                                                onClick={() => setClickedRowIndex(clickedRowIndex === index ? null : index)} // Toggle expansion
                                            >
                                                <td> {moment(e.collected_at).format("YYYY-MM-DD").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })} </td>
                                                <td>{e.waste_provided === 1 ? 'Yes' : 'No'}</td>
                                                <td>{e.waste_category === '[]' ? 'N/A' : JSON.parse(e.waste_category).join(', ')}</td>
                                                <td>{e.is_segregate === 1 ? 'Yes' : 'No'}</td>
                                                <td>{e.ratings === null ? 'NA' : e.ratings}</td>
                                                <td>
                                                    {e.remark === '' || e.remark === null ? 'NA' : (
                                                        <span
                                                            onClick={() => {
                                                                toggleRemarkExpansion(index);
                                                            }}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            ...
                                                        </span>
                                                    )}
                                                </td>
                                            </tr>
                                            {clickedRowIndex === index && (
                                                <tr className={`transition-row ${clickedRowIndex === index ? 'expanded' : ''}`}>
                                                    <td colSpan={6}>
                                                        <AiIMage
                                                            imagesArr={e?.image_names?.split(",") || []}
                                                            images_names={e?.image_names}
                                                            rating={e?.ratings === null ? null : e?.ratings.split(',')}
                                                            category={e?.responses !== null ? e?.responses.split("}") : null}
                                                        />
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PropertyDetails;
