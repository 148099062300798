import React, { useState } from "react";
import "./Sidebar2.css"; // Ensure Sidebar.css is in the same directory or adjust the path accordingly.
import { Link } from "react-router-dom";

const Sidebar2 = ({ isExpanded, setIsExpanded }) => {

    const toggleSidebarHandler = () => {
        const sidebar = document.getElementById("sidebar");
        const menuLabels = document.querySelectorAll(".sidebar .menu a span");
        if (sidebar) {
            if (isExpanded) {
                sidebar.classList.remove("isShrinked");
            } else {
                sidebar.classList.add("isShrinked");
            }
        }

        setIsExpanded(!isExpanded);
    };

    const pages = ['Properties', 'driver', 'collection', 'report', 'dailyWaste']

    return (
        <>
            <div className="sidebar " id="sidebar">
                <button className="btn_toggle" id="btn_sidebar_toggle" onClick={toggleSidebarHandler}>
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M12.8125 4.375L7.1875 10L12.8125 15.625"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </button>

                <div className="menus ">
                    <div className="top_menu">
                        <ul className="menu mt-4">
                            {pages.map((e, i) => {
                                return (

                                    <li key={i} >
                                        <a title={e}>
                                            <Link className=" linkes p-0" to={`/${e.toLowerCase()}`}>
                                                <img src={`/assets/images/sidebar/${e}.png`} alt={`${e} Icon`} />
                                                <span className="anc position-relative "  >{e.charAt(0).toUpperCase() + e.slice(1)}</span>
                                            </Link>
                                        </a>
                                    </li>

                                )
                            })}

                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sidebar2;
